import {BrowserRouter, Route, Switch} from "react-router-dom";
import NavBar from './components/NavBar';
import Home from './components/Home';
import About from './components/About';
import Projects from "./components/Projects";
import Contact from "./components/Contact";


function App() {
  return (
    <BrowserRouter>
      <NavBar />
        <Switch>
          {/* <Route component={Home} path='/' exact /> */}
          <Route component={About} path='/about' />
          <Route component={Projects} path='/projects' />
          <Route component={Contact} path='/contact' />
        </Switch>
      <Home />
      <About />
      <Projects />
    </BrowserRouter>
  )
}

export default App;
