import React from "react";
import { NavLink } from "react-router-dom";
import { SocialIcon } from "react-social-icons";

//import "tailwindcss/tailwind.css"

const btn = document.querySelector("button.mobile-menu-button");
const menu = document.querySelector(".mobile-menu");

if(btn){
    btn.addEventListener("click", () => {
        menu.classList.toggle("hidden");
    }); 
}
// if(btn){
//     btn.addEventListener("touchend", () => {
//         menu.classList.toggle("hidden");
//     }); 
// }

export default function NavBar() {
    return (
        <header className="bg-blue-800">
            <div className="container mx-auto flex justify-between">
                <nav className="flex hidden md:block">
                    <NavLink
                        to="/"
                        exact
                        className="py-2 mr-3 text-white hover:text-yellow-200 name text-4xl font-bold tracking-widest"
                        activeClassName="text-white"
                    >Joe Sutton
                    </NavLink>
                    <NavLink
                        to="/about"
                        className="inline-flex items-center py-2 px-6 my-5 rounded text-white hover:text-yellow-200"
                        activeClassName="text-blue-700 bg-yellow-200"
                    >About
                    </NavLink>
                    <NavLink
                        to="/projects"
                        className="inline-flex items-center py-2 px-6 my-5 rounded text-white hover:text-yellow-200"
                        //activeClassName="text-yellow-300 bg-yellow-100"
                        activeClassName="text-blue-700 bg-yellow-200"
                    >Projects
                    </NavLink>
                    {/* <NavLink>Resume</NavLink> */}
                    <NavLink
                        to="/contact"
                        className="inline-flex items-center py-2 px-6 my-5 rounded text-white hover:text-yellow-200"
                        activeClassName="text-blue-700 bg-yellow-200"
                    >Contact
                    </NavLink> 
                </nav>
                {/* </div> */}
                <div className="flex hidden md:block inline-flex py-2 px-3 my-4">
                    <SocialIcon 
                        url="https://github.com/Jsutton23/" 
                        className="mr-2" 
                        target="_blank"
                        bgColor="#FAF089" 
                        //fgColor="#fff" 
                        style={{ height: 35, width: 35}} 
                    />
                    {/* <SocialIcon 
                        url="https://music.apple.com/us"
                        className="mr-4" 
                        target="_blank"
                        bgColor="#FAF089" 
                        //fgColor="#fff" 
                        style={{ height: 35, width: 35}} 
                    /> */}
                    <SocialIcon 
                        url="https://www.linkedin.com" 
                        className="mr-2" 
                        target="_blank"
                        bgColor="#FAF089" 
                        //fgColor="#FAF089" 
                        style={{ height: 35, width: 35}} 
                    />
                    {/* <a>
                        Resume
                    </a> */}
                </div>
            </div>
            {/* Mobile menu */}
            <div className="md:hidden flex items-center">
                <button className="outline-none mobile-menu-button">
                    <svg
                        className="w-10 h-10 m-3 text-white"
                        x-show="!showMenu"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                    <path d="M4 6h16M4 12h16M4 18h16"></path>
                    </svg>
	            </button>
            </div>
            {/* <!-- mobile menu --> */}
			<div className="hidden mobile-menu">
				<ul className="">
                    <li>
                        <nav>
                        <NavLink
                           to="/about"
                           className="block py-4 px-2 text-white text-sm bg-blue-800"
                           activeClassName="text-yellow-200 bg-yellow-200" 
                        >About</NavLink>
                        </nav>
                    </li>
                    <li>
                        <nav>
                            <NavLink
                            to="/projects"
                            className="block py-4 px-2 text-white text-sm bg-blue-800"
                            activeClassName="text-yellow-200 bg-yellow-200" 
                            >Projects</NavLink>
                        </nav>
                    </li>
                    <li>
                        <nav>
                            <NavLink
                            to="/contact"
                            className="block py-4 px-2 text-white text-sm bg-blue-800"
                            activeClassName="text-yellow-200 bg-yellow-200" 
                            >Contact Me</NavLink>
                        </nav>
                    </li>
					    {/* <li className="active">
                        <a href="index.html" class="block text-sm px-2 py-4 text-white bg-green-500 font-semibold">Home</a>
                    </li>
					<li>
                        <a href="#services" class="block text-sm px-2 py-4 hover:bg-green-500 transition duration-300">Services</a>
                    </li>
					<li>
                        <a href="#about" class="block text-sm px-2 py-4 hover:bg-green-500 transition duration-300">About</a>
                    </li>
					<li>
                        <a href="#contact" class="block text-sm px-2 py-4 hover:bg-green-500 transition duration-300">Contact Us</a>
                    </li> */}
				</ul>
			</div>
            
        </header>
    )
}