import React, {useEffect, useState} from "react";
import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";
import githubLogo from '../images/icons8-github.svg';


const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
    return builder.image(source);
}

export default function Projects() {
    const [projectData, setProjectData] = useState(null)

    useEffect(() => {
        sanityClient.fetch(`*[_type == "project"]{
            title,
            description,
            "stack": stack[]->title,
            "stackID": stack[]->,
            projectType,
            link,
            mainImage,
        }`
        )
        .then((data) => setProjectData(data))
        .catch(console.error)
    }, []);   //the "[]" makes it run only once

    
    
    return (
        <main className="bg-yellow-200 min-h-screen p-12">
        <section className="container mx-auto">
            <h1 className="text-3xl lg:text-5xl text-blue-800 flex justify-center">Personal Projects</h1>
            <h2 className="lg:text-lg text-blue-800 flex justify-center mb-12">What I've been working on..</h2>
           
            <section className="grid lg:grid-cols-3 gap-8 flex flex-col justify-center ">
                
                {projectData && projectData.map((project, index) => (

                    <article className="relative rounded-lg shadow-xl bg-white p-8">
                            {/* <div>
                                <img src={project.mainImage}/>
                            </div> */}
                        {/* Project Header */}
                        <h3 className="text-gray-800 text-4xl font-bold mb-2 flex justify-center hover:text-blue-700">
                            <a 
                                href={project.link} 
                                alt={project.title}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {project.title} 
                            </a>
                        </h3>
                        {/* Project Descript */}
                        <div>
                            <p className="my-6 flex sm:justify-center text-xl text-gray-700 leading-relaxed">
                                {project.description}
                            </p>
                        </div>  
                            {/* Project Stack Cards */}
                            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-5 lg:mr-4 mb-5">
                                {project.stackID && project.stackID.map((image, index) => (
                                    <div className="card">
                                        <div className="card-body rounded-full w-20 h-20">
                                            <img 
                                                src={urlFor(image.mainImage)}
                                                className="card-front p-2 rounded-full w-auto h-auto"
                                                alt="company logo"
                                            />
                                            <span className="card-back text-xs rounded-full w-auto h-auto flex flex-wrap justify-center content-center">
                                                <strong>{image.title}</strong>
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        {/* Source Code Button */}
                        <a 
                            href={project.link} 
                            rel="noopener noreferrer" 
                            target="_blank" 
                            className="justify-center flex flex-grow text-blue-900 font-bold"
                        >
                            <button className="items-center grid grid-cols-3 lg:grid-cols-4 inset-x-0 bottom-0 mt-5 flex-grow bg-blue-600 transition duration-500 transform hover:scale-110 text-white rounded-full w-auto text-md">
                                <img 
                                    src={githubLogo} 
                                    className="inline-block col-end-1"
                                    alt="github logo"
                                    >
                                </img>
                                <span className="inline-block col-start-1 col-span-4 flex flex-wrap lg:ml-5 text-sm lg:text-lg justify-center">
                                    View Source Code
                                </span>
                            </button>
                        </a>
                    </article>
                ))}
            </section>
        </section>
    </main>
    )
}