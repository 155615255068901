import React, {useEffect, useState} from "react";
import sanityClient from "../client.js";
// import imageUrlBuilder from "@sanity/image-url";
import BlockContent from "@sanity/block-content-to-react";
//MERN
import MongoLogo from '../images/mongodb-icon.svg';
import ExpressLogo from '../images/expressjs-icon.svg';
import ReactLogo from '../images/reactjs-icon.svg';
import NodeLogo from '../images/nodejs-icon.svg';
//Basics
import JSLogo from '../images/javascript-icon.svg';
import HtmlLogo from '../images/w3_html5-icon.svg';
import CSSLogo from '../images/icons8-css3.svg';
//DevOps / SCM
import JiraLogo from '../images/atlassian_jira-icon.svg';
import bitbucketLogo from '../images/icons8-bitbucket.svg';
import gitLogo from '../images/git-scm-icon.svg';
import bashLogo from '../images/gnu_bash-icon.svg';
import githubLogo from '../images/icons8-github.svg';
import jenkinsLogo from '../images/jenkins-icon.svg';
import linuxLogo from '../images/linux-icon.svg';
import redhatLogo from '../images/redhat-icon.svg';
//gedit
//groovy
//docker

//Exploring
import tailwindLogo from '../images/tailwindcss-icon.svg';
import bootstrapLogo from '../images/icons8-bootstrap.svg';
//sanity.io
import sanityLogo from '../images/sanityio.png';
//react Native - same logo as react
import swiftLogo from '../images/icons8-swift.svg';
//Typescript
//Svelte


//import author from "../../studio/schemas/author.js";


// const builder = imageUrlBuilder(sanityClient);
// function urlFor(source) {
//     return builder.image(source);
// }



export default function About() {
    const [authorData, setAuthor] = useState(null);
    useEffect(() => {
        sanityClient.fetch(`*[_type == "author"]{
            name,
            bio,
            "authorImage": image.asset->url,
            "stack": stack[]->title,
        }`
        )
        .then((data) => setAuthor(data))
        .catch(console.error);
    }, [], console.log(authorData));
    
    // const [isOpen, setIsOpen] = useState(false);

    // const toggle = () => {
    //   setIsOpen(!isOpen);
    // };

    
    return (
        <main className="relative mx-auto bg-yellow-200">
        {/* <img /> */}
        {/* <header>
            About
        </header> */}
            <div className="p-8 pt-25 mx-auto relative">
            {authorData && authorData.map((author, index) => (
                <section className="bg-white rounded-lg shadow-2xl lg:flex lg:m-20 lg:p-10" >
                    {/* <img src={urlFor(author.authorImage).url()}
                        className="rounded-full w-32 h-32 lg:w-64 lg:h-64 mr-8" 
                        alt={author.name} 
                    /> */}
                    <div className="text-black text-lg flex flex-col p-2">
                        <h1 className="lg:text-7xl text-5xl lg:mb-4">
                            Hi! I'm{" "}
                            {/* gill sans is the font on the logo */}
                            <span className="text-blue-800">Joe.</span> 
                        </h1>
                        {/* <div className="text-black prose-xl prose-xl sm:hidden"> */}
                            {/* <Collapse isOpen={isOpen}>
                                <BlockContent 
                                    blocks={author.bio}
                                    projectId="xygzaaot"
                                    dataset="production"
                                />
                            </Collapse>
                           
                            <button onClick={toggle}>
                                <p>Click to see more...</p>
                            </button> */}
                        {/* </div> */}
                            <div className="prose-xl h-72 lg:h-auto overflow-hidden">
                                <BlockContent 
                                    blocks={author.bio}
                                    projectId="xygzaaot"
                                    dataset="production"
                                />
                            </div>
                        
                    </div>
                </section>
            ))}
            </div>
        <div className="p-10 lg:pt-25 container mx-auto relative">
            {/* MY SKILLS SECTION */}
            <section className="mt-20 bg-white divide-y-2 divide-blue-800 rounded-lg shadow-2xl lg:p-20 flex flex-col justify-center">
                <div className="pt-3 flex justify-center">
                    <h2 className="text-2xl text-blue-800 lg:text-6xl justify-center">
                        My Skills
                    </h2>
                </div>
                <div className="m-9 lg:m-10 pt-10 grid grid-cols-2 gap-5 lg:grid-cols-4 lg:gap-8 flex justify-center">
                    {/* HTML5 */}
                    <div className="card flex">
                        <div className="card-body rounded-full shadow-2xl lg:w-44 lg:h-44">
                            <img src={HtmlLogo}
                                alt="HTML Logo"
                                className="p-5 rounded-full card-front lg:w-44 lg:h-44" 
                            >
                            </img>
                            <div className="rounded-full card-back flex flex-wrap justify-center content-center lg:text-3xl lg:w-44 lg:h-44">
                                <strong>HTML5</strong>
                            </div>
                        </div>
                    </div>
                    {/* Javascript */}
                    <div className="card">
                        <div className="card-body rounded-full shadow-2xl lg:w-44 lg:h-44">
                            <img src={JSLogo}
                                alt="Javascript Logo"
                                className="p-5 rounded-full card-front lg:w-44 lg:h-44"
                            >
                            </img>
                            <div className="rounded-full card-back flex flex-wrap justify-center content-center lg:w-44 lg:h-44 lg:text-3xl">
                                <strong>Javascript</strong>
                            </div>
                        </div>
                    </div>
                    {/* CSS */}
                    <div className="card">
                        <div className="card-body rounded-full shadow-2xl lg:w-44 lg:h-44">
                            <img src={CSSLogo}
                                alt="CSS Logo"
                                className="p-5 rounded-full card-front lg:w-44 lg:h-44"
                            >
                            </img>
                            <div className="rounded-full card-back flex flex-wrap justify-center content-center lg:w-44 lg:h-44 lg:text-3xl">
                                <strong>CSS</strong>
                            </div>
                        </div>
                    </div>
                    {/* MongoDB */}
                    <div className="card">
                        <div className="card-body rounded-full shadow-2xl lg:w-44 lg:h-44">
                            <img src={MongoLogo}
                                alt="MongoDB Logo"
                                className="p-5 rounded-full card-front lg:w-44 lg:h-44"
                            >
                            </img>
                            <div className="rounded-full card-back flex flex-wrap justify-center content-center lg:w-44 lg:h-44 lg:text-3xl">
                                <strong>MongoDB</strong>
                            </div>
                        </div>
                    </div>
                    {/* Express */}
                    <div className="card">
                        <div className="card-body rounded-full shadow-2xl lg:w-44 lg:h-44">
                            <img src={ExpressLogo}
                                alt="ExpressJS Logo"
                                className="p-5 rounded-full card-front lg:w-44 lg:h-44"
                            >
                            </img>
                            <div className="rounded-full card-back flex flex-wrap justify-center content-center lg:w-44 lg:h-44 lg:text-3xl">
                                <strong>Express JS</strong>
                            </div>
                        </div>
                    </div>
                    {/* React */}
                    <div className="card">
                        <div className="card-body rounded-full shadow-2xl lg:w-44 lg:h-44">
                            <img src={ReactLogo}
                                alt="ReactJS Logo"
                                className="p-5 rounded-full card-front lg:w-44 lg:h-44" 
                            >
                            </img>
                            <div className="rounded-full card-back flex flex-wrap justify-center content-center lg:w-44 lg:h-44 lg:text-3xl">
                                <strong>React JS</strong>
                            </div>
                        </div>
                    </div>
                    {/* Node JS */}
                    <div className="card">
                        <div className="card-body rounded-full shadow-2xl lg:w-44 lg:h-44">
                            <img src={NodeLogo}
                                alt="NodeJS Logo"
                                className="p-5 rounded-full card-front lg:w-44 lg:h-44" 
                            >
                            </img>
                            <div className="rounded-full card-back flex flex-wrap justify-center content-center lg:w-44 lg:h-44 lg:text-3xl">
                                <strong>Node JS</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
                
            {/* SCM & DEVOPS SECTION */}
            <section className="mt-20 bg-white divide-y-2 divide-blue-800 rounded-lg shadow-2xl lg:p-20 flex flex-col justify-center">
                <div className="pt-3 flex justify-center">
                    <h2 className="text-2xl text-blue-800 lg:text-6xl justify-center">
                        DevOps Tools
                    </h2>
                </div>
                <div className="m-9 lg:m-10 pt-10 grid grid-cols-2 gap-5 lg:grid-cols-4 lg:gap-8 flex justify-center">
                    {/* Bitbucket Card */}
                    <div className="card">
                        <div className="card-body rounded-full shadow-2xl lg:w-44 lg:h-44">
                            <img src={bitbucketLogo}
                                alt="Bitbucket Logo"
                                className="p-5 rounded-full card-front lg:w-44 lg:h-44" 
                            >
                            </img>
                            <div className="rounded-full card-back flex flex-wrap justify-center content-center lg:w-44 lg:h-44 lg:text-3xl">
                                <strong>Bitbucket</strong>
                            </div>
                        </div>
                    </div>
                    {/* JIRA Card */}
                    <div className="card">
                        <div className="card-body rounded-full shadow-2xl lg:w-44 lg:h-44">
                            <img src={JiraLogo}
                                alt="Jira Logo"
                                className="p-5 rounded-full card-front lg:w-44 lg:h-44" 
                            >
                            </img>
                            <div className="rounded-full card-back flex flex-wrap justify-center content-center lg:w-44 lg:h-44 lg:text-3xl">
                                <strong>Jira</strong>
                            </div>
                        </div>
                    </div>
                    {/* GIT Card */}
                    <div className="card">
                        <div className="card-body rounded-full shadow-2xl lg:w-44 lg:h-44">
                            <img src={gitLogo}
                                alt="Git Logo"
                                className="p-5 rounded-full card-front lg:w-44 lg:h-44" 
                            >
                            </img>
                            <div className="rounded-full card-back flex flex-wrap justify-center content-center lg:w-44 lg:h-44 lg:text-3xl">
                                <strong>Git</strong>
                            </div>
                        </div>
                    </div>
                    {/* GITHUB Card */}
                    <div className="card">
                        <div className="card-body rounded-full shadow-2xl  lg:w-44 lg:h-44">
                            <img src={githubLogo}
                                alt="Github Logo"
                                className="p-5 rounded-full card-front lg:w-44 lg:h-44" 
                            >
                            </img>
                            <div className="rounded-full card-back flex flex-wrap justify-center content-center lg:w-44 lg:h-44 lg:text-3xl">
                                <strong>Github</strong>
                            </div>
                        </div>
                    </div>
                    {/* Linux Card */}
                    <div className="card">
                        <div className="card-body rounded-full shadow-2xl lg:w-44 lg:h-44">
                            <img src={linuxLogo}
                                alt="Linux Logo"
                                className="p-5 rounded-full card-front lg:w-44 lg:h-44" 
                            >
                            </img>
                            <div className="rounded-full card-back flex flex-wrap justify-center content-center lg:w-44 lg:h-44 lg:text-3xl">
                                <strong>Linux</strong>
                            </div>
                        </div>
                    </div>
                    {/* BASH Card */}
                    <div className="card">
                        <div className="card-body rounded-full shadow-2xl lg:w-44 lg:h-44">
                            <img src={bashLogo}
                                alt="Bash Logo"
                                className="p-5 rounded-full card-front lg:w-44 lg:h-44" 
                            >
                            </img>
                            <div className="rounded-full card-back flex flex-wrap justify-center content-center lg:w-44 lg:h-44 lg:text-3xl">
                                <strong>Bash Scripting</strong>
                            </div>
                        </div>
                    </div>
                    {/* Redhat Linux Card */}
                    <div className="card">
                        <div className="card-body rounded-full shadow-2xl lg:w-44 lg:h-44">
                            <img src={redhatLogo}
                                alt="Redhat Logo"
                                className="p-5 rounded-full card-front lg:w-44 lg:h-44" 
                            >
                            </img>
                            <div className="rounded-full card-back flex flex-wrap justify-center content-center lg:w-44 lg:h-44 lg:text-3xl">
                                <strong>Redhat Linux</strong>
                            </div>
                        </div>
                    </div>
                    {/* JENKINS Card */}
                    <div className="card">
                        <div className="card-body rounded-full shadow-2xl lg:w-44 lg:h-44">
                            <img src={jenkinsLogo}
                                alt="Jenkins Logo"
                                className="p-5 rounded-full card-front lg:w-44 lg:h-44" 
                            >
                            </img>
                            <div className="rounded-full card-back flex flex-wrap justify-center content-center lg:w-44 lg:h-44 lg:text-3xl">
                                <strong>Jenkins</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

                {/* EXPLORING Tech SECTION */}
            <section className="mt-20 bg-white divide-y-2 divide-blue-800 rounded-lg lg:p-20 flex flex-col justify-center shadow-2xl">
                <div className="pt-3 flex justify-center">
                    <h2 className="text-2xl text-blue-800 lg:text-6xl justify-center">
                        Tech I'm Exploring
                    </h2>
                </div>
                <div className="m-9 lg:m-10 pt-10 grid grid-cols-2 gap-5 lg:grid-cols-4 lg:gap-8 flex justify-center">
                    {/* TAILWIND Card */}
                    <div className="card">
                        <div className="card-body rounded-full shadow-2xl lg:w-44 lg:h-44">
                            <img src={tailwindLogo}
                                alt="Tailwind CSS Logo"
                                className="p-5 rounded-full card-front lg:w-44 lg:h-44" 
                            >
                            </img>
                            <div className="rounded-full card-back flex flex-wrap justify-center content-center lg:w-44 lg:h-44 lg:text-3xl">
                                <strong>Tailwind CSS</strong>
                            </div>
                        </div>
                    </div>
                    {/* Bootstrap Card */}
                    <div className="card">
                        <div className="card-body rounded-full shadow-2xl lg:w-44 lg:h-44">
                            <img src={bootstrapLogo}
                                alt="Bootstrap Logo"
                                className="p-5 rounded-full card-front lg:w-44 lg:h-44 " 
                            >
                            </img>
                            <div className="rounded-full card-back flex flex-wrap justify-center content-center lg:w-44 lg:h-44 lg:text-3xl">
                                <strong>Bootstrap</strong>
                            </div>
                        </div>
                    </div>
                    {/* React Native Card */}
                    <div className="card">
                        <div className="card-body rounded-full shadow-2xl lg:w-44 lg:h-44">
                            <img src={ReactLogo}
                                alt="React Native Logo"
                                className="p-5 rounded-full card-front lg:w-44 lg:h-44 " 
                            >
                            </img>
                            <span className="rounded-full card-back flex flex-wrap justify-center content-center lg:w-44 lg:h-44 lg:text-3xl">
                                <strong>React Native</strong>
                            </span>
                        </div>
                    </div>
                    {/* SWIFT Card */}
                    <div className="card">
                        <div className="card-body rounded-full shadow-2xl lg:w-44 lg:h-44">
                            <img src={swiftLogo}
                                alt="Swift Logo"
                                className="p-5 rounded-full card-front lg:w-44 lg:h-44" 
                            >
                            </img>
                            <div className="rounded-full card-back flex flex-wrap justify-center content-center lg:w-44 lg:h-44 lg:text-3xl">
                                <strong>Swift</strong>
                            </div>
                        </div>
                    </div>
                    {/* Sanity.io Card */}
                    <div className="card">
                        <div className="card-body rounded-full shadow-2xl lg:w-44 lg:h-44">
                            <img src={sanityLogo}
                                alt="Sanity.io Logo"
                                className="p-5 rounded-full card-front lg:w-44 lg:h-44" 
                            >
                            </img>
                            <div className="rounded-full card-back flex flex-wrap justify-center content-center lg:w-44 lg:h-44 lg:text-3xl">
                                <strong>Sanity.io</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </main>
    )
}

/* Logic */
// const Collapse = ({ isOpen, children }) => {
//     const ref = useRef(null);
  
//     const inlineStyle = isOpen
//       ? { height: ref.current?.scrollHeight }
//       : { height: 0 };
  
//     return (
//       <div
//         ref={ref}
//         aria-hidden={!isOpen}
//         style={inlineStyle}
//         className="transition-height ease mt-2 text-gray-600 overflow-hidden duration-300"
//       >
//         {children}
//       </div>
//     );
//   };