// import { directive } from "@babel/types";
import React from "react";
import DownArrow from '../images/icons8-down-96.png'
// import About from "./About"
// import Projects from "./Projects";

export default function Home() {
    return (
        //<main className="bg-yellow-200">
        <main className="grid bg-yellow-200 relative flex justify-center lg:px-8 home-name name min-h-screen">
            {/* <section className="relative flex justify-center min-h-screen pt-12 lg:pt-64 px-8"> */}
            <div className="flex flex-col justify-center lg:grid-rows-2 mb-20 lg:m-52">
                <h1 className="pt-5 lg:text-8xl text-5xl text-blue-800 font-bold leading-none"> 
                    Joe Sutton
                </h1>
                <h2 className="flex justify-center lg:text-5xl text-2xl text-blue-800 lg:pt-10">
                    Web Developer
                </h2>
            </div>
            <div className="lg:scroll relative m-auto justify-center">
                <img
                    src={DownArrow}
                    alt="scroll down icon"
                >
                </img>
            </div>
        </main>
    )
}