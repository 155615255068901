// import React, {useEffect, useState} from "react";

import React from 'react';

function Contact() {
    return (
        <form action="POST" data-netlify="true">
            <div className="container ml-5">
            <header>Contact Me</header>
            <div>
                <h3>Name</h3> 
                <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                />
                
            </div>
            <div>
                <h3>Email</h3>
                <input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Email"
                />
            </div>
            <div>
                <h3>Message</h3>
                <textarea
                    name="message"
                    id="message"
                    placeholder="Message goes here...."
                    rows="7"
                >
                </textarea>
            </div>
            <div>
                <h3>Upload</h3>
                <input
                    type="file"
                    name="myfile"
                    id="myfile"
                    placeholder="Upload File"
                    rows="7"
                />
            </div>
            {/* <div>
                <div
                    className="field"
                    data-netlify-recaptcha="true">
                </div>
            </div> */}
            <div>
                <button>Submit</button>
            </div>
            </div> 
        </form>
    )
}

export default Contact
